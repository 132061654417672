import React from "react";
import Hero from "../components/sections/Hero";
import { Footer } from "../components/sections/Footer";

import FeaturesTiles from "../components/sections/FeaturesTiles";

const Home = () => {
  return (
    <div>
      <Hero />
      <FeaturesTiles />
      <Footer />
    </div>
  );
};

export default Home;
