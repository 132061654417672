import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import imagebackground from "../../../assets/images/background_image.png";
import { Divider } from "@mui/material";

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    paragraph: PropTypes.string,
    backgroundImage: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
  tag: PropTypes.oneOf(["h1", "h2", "h3"]),
};

const defaultProps = {
  children: null,
  tag: "h2",
};

const SectionHeader = ({ className, data, children, tag, ...props }) => {
  const classes = classNames("section-header", className);

  const Component = tag;

  const backgroundStyle = {
    backgroundImage: `url(${data.backgroundImage || imagebackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100vh",
    position: "relative",
    borderRadius: "20px", // Add border radius
    overflow: "hidden",
  };

  const textStyle = {
    position: "relative",
    color: "white",
    zIndex: 2,
    marginTop: 60,
  };

  return (
    <>
      {(data.title || data.paragraph) && (
        <div style={{ marginTop: 30 }}>
          <div {...props} className={classes} style={backgroundStyle}></div>
          <div>
            <div className="container-xs">
              {children}
              {data.title && (
                <Component style={textStyle}>{data.title}</Component>
              )}
              {data.paragraph && <p>{data.paragraph}</p>}
            </div>
          </div>
        </div>
      )}

      <Divider
        orientation="vertical"
        style={{
          backgroundColor: "#dee2e6",
          margin: "0 32px",
          marginBottom: 100,
          marginTop: 100,
        }}
      />
    </>
  );
};

SectionHeader.propTypes = propTypes;
SectionHeader.defaultProps = defaultProps;

export default SectionHeader;
