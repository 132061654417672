import React from "react";
import { MDBFooter, MDBIcon } from "mdb-react-ui-kit";
import Image from "../elements/Image";
import { AppInfo } from "../../constants/appInfo"; // Adjust the path as necessary

export const Footer = () => {
  return (
    <MDBFooter
      style={{
        fontSize: "12px",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: "40px",
        paddingRight: "40px",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      }}
    >
      <Image
        src={require("./../../assets/icons-ma/ic_museumsaround_white_outline.png")}
        width={50} // Reduced image size
      />
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <a
            href={AppInfo.MAprivacyPolicyURL}
            target="_blank" // Opens in a new tab
            rel="noopener noreferrer" // Security measures to prevent vulnerabilities
            className="text-xs text-decoration-underline"
            style={{ fontSize: "12px" }} // Smaller font for links
          >
            Privacy Policy
          </a>
          <div style={{ width: 2 }} />
          <a
            href={AppInfo.MAtermsConditionsURL}
            target="_blank"
            rel="noopener noreferrer"
            className="text-xs text-decoration-underline"
            style={{ fontSize: "12px" }} // Smaller font for links
          >
            Terms and Conditions
          </a>
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "11px", // Reduced font size for copyright text
          }}
        >
          Copyright © 2024 MuseumsAround ®
        </div>
      </div>
      <a
        href="https://instagram.com/museumsaroundapp?igshid=YmMyMTA2M2Y="
        style={{ fontSize: "14px" }} // Adjust icon size if needed
      >
        <MDBIcon fab icon="instagram" />
      </a>
    </MDBFooter>
  );
};
