import React from 'react';
import exampleImage from '../../assets/images/image_appstore.png';

const AppleStoreButton = () => {
  return (
      <div>
        <div className="storeimg-container">
          <img src={exampleImage} alt="Apple Store" />
        </div>
      </div>
  );
};

export default AppleStoreButton;
