import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import detailSvg from "../../assets/images/detail_img.svg";
import mapSvg from "../../assets/images/map_img.svg";
import saveSvg from "../../assets/images/save_img.svg";
import searchSvg from "../../assets/images/search_img.svg";
import { Divider } from "@mui/material"; // Import the Divider component

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const featureRefs = useRef([]);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768); // Check if screen is <= 768px
    };

    window.addEventListener("resize", handleResize); // Add resize listener
    return () => window.removeEventListener("resize", handleResize); // Cleanup listener
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Apply animation styles
            entry.target.style.transition =
              "transform 0.6s ease-out, opacity 0.6s ease-out";
            entry.target.style.transform = "translateY(0) scale(1.05)"; // Pop effect
            entry.target.style.opacity = "1"; // Fade in
          } else {
            // Reset styles when out of view
            entry.target.style.transition =
              "transform 0.6s ease-out, opacity 0.6s ease-out";
            entry.target.style.transform = "translateY(20px) scale(1)";
            entry.target.style.opacity = "0"; // Fade out
          }
        });
      },
      { threshold: 0.3 } // Trigger when 30% of the item is visible
    );

    // Observe each feature item
    featureRefs.current.forEach((feature) => {
      if (feature) observer.observe(feature);
    });

    // Cleanup observer
    return () => observer.disconnect();
  }, []);

  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    title: "For the Love of Art, History, and Exploration",
    paragraph:
      "MuseumsAround is your personal gateway to exploring wonderful museums and hidden gems, whether you're at home or on the go. Immerse yourself in a world of cultural wonders as the app transports you across the globe—from iconic city landmarks to off-the-beaten-path treasures waiting to be discovered.",
  };

  const imageContainer = {
    marginBottom: "20px",
    display: "flex", // Make it a flex container
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    height: "200px", // Optional: Set height for vertical centering
  };

  // Define styles as objects outside JSX
  const containerStyle = {
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "20px",
    backgroundColor: "#000000", // Black background
    color: "#FFFFFF", // White text
  };

  const cardStyle = {
    background: "#1a1a1a", // Slightly lighter black for contrast
    borderRadius: "10px",
    padding: "30px",
    textAlign: "center",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
  };

  const hoverEffect = {
    onMouseEnter: (e) => {
      e.currentTarget.style.transform = "translateY(-5px)";
      e.currentTarget.style.boxShadow =
        "0px 10px 20px rgba(255, 255, 255, 0.1)";
    },
    onMouseLeave: (e) => {
      e.currentTarget.style.transform = "translateY(0)";
      e.currentTarget.style.boxShadow = "none";
    },
  };

  const imageStyle = {
    width: "220px", // Larger image size
    height: "220px",
  };

  const headingStyle = {
    fontSize: "20px",
    fontWeight: "600",
    marginBottom: "10px",
  };

  const paragraphStyle = {
    fontSize: "16px",
    lineHeight: "1.8",
  };

  const gridStyle = {
    display: "grid",
    gap: "64px",
    gridTemplateColumns: isSmallScreen ? "1fr" : "repeat(2, 1fr)", // Dynamically adjust columns
  };

  // Features data
  const features = [
    {
      img: mapSvg,
      title: "Explore Museums from Anywhere",
      description:
        "Navigate through an enhanced map pinpointing both 🎨 well-known and lesser-known museums offering a surprising experience.",
    },
    {
      img: saveSvg,
      title: "Personalize Your Journey",
      description:
        "💾 Create your collection of favorite museums with a simple tap. Whether it's an awe-inspiring museum or a hidden underground gem, easily save them for future visits.",
    },
    {
      img: detailSvg,
      title: "Dive into Museum Details",
      description:
        "Dive into museum profiles to acquire 📚 more detail, information, and intriguing anecdotes in each location.",
    },
    {
      img: searchSvg,
      title: "Filter and Search with Ease",
      description:
        "🔎 Looking for a specific type of museum or are you curious about which museums open on Monday? With powerful filtering options, It lets you tailor your museum discovering experience, ensuring you find the perfect match for your interests.",
    },
  ];

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div style={containerStyle}>
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div style={gridStyle}>
              {features.map((feature, index) => (
                <div
                  key={index}
                  style={cardStyle}
                  ref={(el) => (featureRefs.current[index] = el)} // Assign ref to each feature
                >
                  <div style={imageContainer}>
                    <img
                      src={feature.img}
                      alt={feature.title}
                      style={imageStyle}
                    />
                  </div>
                  <h5 style={headingStyle}>{feature.title}</h5>
                  <p style={paragraphStyle}>{feature.description}</p>
                </div>
              ))}
            </div>
          </div>

          {/*<div className="tiles-item reveal-from-bottom"
                 data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                        src={require(
                            './../../assets/images/feature-tile-icon-03.svg')}
                        alt="Features tile icon 03"
                        width={64}
                        height={64}/>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-4">
                    📚 Adventure Meets Education:
                  </h4>
                  <p className="m-0 text-sm">
                    Step into an immersive learning environment and gain deeper
                    insights into the cultural heritage, unravel hidden
                    narratives, and broaden your intellectual horizons that will
                    leave you in awe. Make each visit an unforgettable
                    experience. </p>
                </div>
              </div>
            </div>*/}

          {/* <div className="tiles-item reveal-from-bottom">
                            <div className="tiles-item-inner">
                                <div className="features-tiles-item-header">
                                    <div className="features-tiles-item-image mb-16">
                                        <Image
                                            src={require('./../../assets/images/feature-tile-icon-04.svg')}
                                            alt="Features tile icon 04"
                                            width={64}
                                            height={64}/>
                                    </div>
                                </div>
                                <div className="features-tiles-item-content">
                                    <h4 className="mt-0 mb-4">
                                        Robust Workflow
                                    </h4>
                                    <p className="m-0 text-sm">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat.
                                    </p>
                                </div>
                            </div>
                        </div>*/}

          {/* <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                            <div className="tiles-item-inner">
                                <div className="features-tiles-item-header">
                                    <div className="features-tiles-item-image mb-16">
                                        <Image
                                            src={require('./../../assets/images/feature-tile-icon-05.svg')}
                                            alt="Features tile icon 05"
                                            width={64}
                                            height={64}/>
                                    </div>
                                </div>
                                <div className="features-tiles-item-content">
                                    <h4 className="mt-0 mb-4">
                                        Robust Workflow
                                    </h4>
                                    <p className="m-0 text-sm">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat.
                                    </p>
                                </div>
                            </div>
                        </div>*/}

          {/* <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
                            <div className="tiles-item-inner">
                                <div className="features-tiles-item-header">
                                    <div className="features-tiles-item-image mb-16">
                                        <Image
                                            src={require('./../../assets/images/feature-tile-icon-06.svg')}
                                            alt="Features tile icon 06"
                                            width={64}
                                            height={64}/>
                                    </div>
                                </div>
                                <div className="features-tiles-item-content">
                                    <h4 className="mt-0 mb-4">
                                        Robust Workflow
                                    </h4>
                                    <p className="m-0 text-sm">
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat.
                                    </p>
                                </div>
                            </div>
                        </div>*/}
        </div>
      </div>

      <Divider
        orientation="vertical"
        style={{ backgroundColor: "#dee2e6", margin: "0 32px" }}
      />
    </section>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
