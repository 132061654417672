import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import { IconButton } from "@material-ui/core";
import iconUrl from "../../assets/icons-ma/ic_museumsaroundwhitelogo.png";
import Image from "../elements/Image";
import Button from "../elements/Button";
import AppleStoreButton from "../buttons/AppleStoreButton";
import PlayStoreButton from "../buttons/PlayStoreButton";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <section {...props} className={outerClasses}>
        <div className="container-sm">
          <div className="hero-content">
            <img
              src={iconUrl}
              style={{ height: "100%", width: 300, marginTop: 80 }}
            />
            <h1
              style={{ fontSize: 30 }}
              className="mt-5 mb-25 reveal-from-bottom"
              data-reveal-delay="200"
            >
              Unlock the World’s Best Museums{" "}
              <span className="text-color-primary">🏛</span>
            </h1>
            <div className="container-sm hero-content-wrapper">
              <p
                className="m-0 mb-32 reveal-from-bottom align-self-center text-start"
                data-reveal-delay="400"
              >
                <span className="text-large">
                  Explore the World of Art, Archaeology, Cultural Heritage,
                  Science, and Technology with a Museum App!
                </span>
                {/*   <br />
                <br />
                <span className="text-small">
                  MuseumsAround is your personal gateway to exploring wonderful
                  museums and hidden gems, whether you're at home or on the go.
                  Immerse yourself in a world of cultural wonders as the app
                  transports you across the globe—from iconic city landmarks to
                  off-the-beaten-path treasures waiting to be discovered.
                </span>
                <br />
                <br />
                <span className="text-small">
                  Whether you're an art enthusiast, history buff, or curious
                  traveler, skip the lines and immerse yourself in culture like
                  never before.
                </span> */}
              </p>

              <Image
                src={require("./../../assets/images/doublevertical.png")}
                className=""
              />
              {/*  <div className="reveal-from-bottom" data-reveal-delay="600">
                <Button
                  tag="a"
                  color="primary"
                  wideMobile
                  href="https://cruip.com/"
                >
                  Get started
                </Button>
                <Button
                  tag="a"
                  color="dark"
                  wideMobile
                  href="https://github.com/cruip/open-react-template/"
                >
                  View on Github
                </Button>
              </div> */}
            </div>
          </div>
          <div>
            <IconButton
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.lifeyapps.museumsaround"
                )
              }
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <PlayStoreButton />
            </IconButton>
            <IconButton
              onClick={() =>
                window.open(
                  "https://apps.apple.com/us/app/museumsaround/id6447110231"
                )
              }
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <AppleStoreButton />
            </IconButton>
          </div>
        </div>
      </section>
    </>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
