import React from 'react';
import exampleImage from '../../assets/images/image_playstore.png';

const PlayStoreButton = () => {
  return (
      <div>
        <div className="storeimg-container">
          <img src={exampleImage} alt="Play Store"/>
        </div>
      </div>
  );
};

export default PlayStoreButton;
